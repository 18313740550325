import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import CpfValidator from '@services/cpf.validator';
import EmailValidator from '@services/email.validator';
import { IDadosQRCode } from '@models/qrCode';
import { CampusLead, CursoLead, EscolaridadeLead, MetodologiaLead, TurnoLead } from '../../models/dadosLead';
import { GeraisService } from '../../services/gerais.service';
import { ToastrService } from 'ngx-toastr';
import { Lead } from '../../models/Lead';
import { LeadService } from '../../services/lead.service';

@Component({
  selector: 'app-qr-captacao',
  templateUrl: './qr-captacao.page.html',
  styleUrls: ['./qr-captacao.page.scss'],
})
export class QrCaptacaoPage implements OnInit {
  captcha: string;

  dadosUrl: IDadosQRCode;

  isLoading = false;
  escolaridades: EscolaridadeLead[];
  metodologias: MetodologiaLead[];
  cursos: CursoLead[];
  campus: CampusLead[];
  turnos: TurnoLead[];
  formLead!: FormGroup;
  disabledBotao = false;
  cursoSelecionado: any = null;
  turnoSelecionado: any = null;
  modalidadeSelecionada: any = null;
  indModalidade: any = null;
  codigoInstituicaoInteresse: any = null;
  codCurso: any = null;
  codCampus: any = null;
  codCampusPai: any = null;
  codTurno: any = null;
  idMetodologia: any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _geraisService: GeraisService,
    private _toastrService: ToastrService,
    private _leadService: LeadService
  ) {
    // Obtenha os parâmetros da URL do QRCode
    this.route.queryParams.subscribe(params => {
      this.dadosUrl = {
        local: params['local'],
        chaveLocal: params['chaveLocal'],
        cidade: params['cidade'],
        bairro: params['bairro'],
        uf: params['uf'],
        idUsuario: params['idUsuario'],
        codigoPDV: params['codigoPDV'],
        marca: params['marca'],
        canal: params['canal'],
        descricaoCampanha: params['descricaoCampanha'],
        campus: params['campus'],
        idCampanhaQRCode: params['idCampanhaQRCode'],
        urlRedirectQRCode: params['urlRedirectQRCode'],
        idSalesforce: params['idSalesforce'],
        canalSalesforce: params['canalSalesforce'],
      };

      if (this.dadosUrl.local !== 'null') {
        console.log(this.dadosUrl.local);
      } else {
        console.log('HighScholl');
      }
      console.log(this.dadosUrl);
    });
  }

  ngOnInit(): void {
    this.initFormLead();
    if (this.dadosUrl?.canal != 'H') {
      this.formLead.controls['anoConclusaoEnsinoMedio'].clearValidators();
      //if (this.dadosUrl?.canal == 'E') {
      //  this.formLead.controls['curso'].clearValidators();
      //  this.formLead.controls['metodologia'].clearValidators();
      //}
      if (this.dadosUrl?.canal == 'T' || this.dadosUrl?.canal == 'T') {
        this.formLead.controls['campus'].setValidators([Validators.required]);
        //this.formLead.controls['metodologia'].setValidators([Validators.required]);
      }
    } else {
      this.formLead.controls['anoConclusaoEnsinoMedio'].setValidators([Validators.required]);
      //this.formLead.controls['metodologia'].clearValidators();
    }

    this.getInfo();
    this.generateCaptcha();
  }

  initFormLead() {
    this.formLead = new FormGroup({
      nomeLead: new FormControl('', [Validators.required]),
      emailLead: new FormControl('', [Validators.required, EmailValidator]),
      telefoneLead: new FormControl('', [Validators.required]),
      cpfLead: new FormControl('', [CpfValidator]),
      empresa: new FormControl(''),
      escolaridade: new FormControl(''),
      tipoCurso: new FormControl(''),
      turno: new FormControl(''),
      curso: new FormControl('', [Validators.required]),
      unidadeInteresse: new FormControl(''),
      metodologia: new FormControl(''),
      campus: new FormControl(''),
      anoConclusaoEnsinoMedio: new FormControl(null, [Validators.required]),
      captcha: new FormControl('', [Validators.required])
    });
  }

  salvarLead() {
    if (this.formLead.controls['captcha'].value != this.captcha) {
      this._toastrService.error('Captcha incorreto');
      this.generateCaptcha();
      return;
    }
    this.isLoading = true;
    let a;
    // Recupera os novos dados de campanha
    const body = new Lead(a);
    //body.localCapturaEscola = this.dadosUrl.local;
    //body.cidade = this.dadosUrl.cidade;
    //body.bairro = this.dadosUrl.bairro;
    //body.uf = this.dadosUrl.uf;
    if (this.dadosUrl.canal !== "H") {
      body.idCampanhaLocalCaptura = +this.dadosUrl.chaveLocal;
    }
    body.idUsuario = +this.dadosUrl.idUsuario;
    //body.codigoPDV = this.dadosUrl.codigoPDV;
    //body.marca = this.dadosUrl.marca;
    //body.canal = this.dadosUrl.canal;
    body.idCampanhaQRCode = this.dadosUrl.idCampanhaQRCode;
    //body.canalSalesforce = this.dadosUrl.canalSalesforce;
    //body.idSalesforce = this.dadosUrl.idSalesforce === "null" ? null : this.dadosUrl.idSalesforce;
    //body.nomeCampanha = this.dadosUrl.descricaoCampanha;
    body.nomeLead = this.formLead.controls['nomeLead'].value;
    body.emailLead = this.formLead.controls['emailLead'].value;
    body.telefoneLead = this.formLead.controls['telefoneLead'].value;
    body.cpfLead = this.formLead.controls['cpfLead'].value;
    body.empresa = this.formLead.controls['empresa'].value.trim() !== '' ? this.formLead.controls['empresa'].value : null;
    body.escolaridade = this.formLead.controls['escolaridade'].value;
    body.tipoCurso = this.formLead.controls['tipoCurso'].value
      ? this.formLead.controls['tipoCurso'].value
      : 'GRADUAÇÃO';

    //campos novos
    body.codCurso = this.codCurso;
    body.curso = this.formLead.controls['curso'].value;
    body.codCampus = this.codCampus;
    body.codCampusPai = this.codCampusPai;
    body.campus = this.formLead.controls['campus'].value.trim() !== '' ? this.formLead.controls['campus'].value : null;
    body.codTurno = this.codTurno;
    body.turno = this.formLead.controls['turno'].value;
    body.metodologia = this.formLead.controls['metodologia'].value;
    body.indModalidade = this.indModalidade;
    body.codigoInstituicaoInteresse = this.codigoInstituicaoInteresse;
    body.anoConclusaoEnsinoMedio = this.formLead.controls['anoConclusaoEnsinoMedio'].value;
    body.idMetodologia = this.idMetodologia;
    body.idEscolaridade = this.escolaridades.find(x => {
      return x.nomeEscolaridade == body.escolaridade;
    })?.id;
    //Gravação do lead
    this._leadService.postLeadQR(body).subscribe(
      response => {
        // Verifica a condição para definir a mensagem
        const mensagem = this.formLead.valid &&
          this.formLead.get('cpfLead').valid &&
          this.formLead.get('cpfLead').value &&
          this.formLead.get('curso').value &&
          this.formLead.get('metodologia').value &&
          this.formLead.get('turno').value &&
          this.formLead.get('campus').value
          ? 'Oportunidade salva com sucesso, obrigado!'
          : 'Lead salvo com sucesso, obrigado!';

        // Exibe a mensagem no toastr
        this._toastrService.success(mensagem);

        // desabilita o botão
        this.disabledBotao = true;
        // se tiver url para redirecionar, manda
        if (this.dadosUrl.urlRedirectQRCode != 'null') {
          window.location.href = this.dadosUrl.urlRedirectQRCode;
        }

      },
      error => {
        this.isLoading = false;

        this._toastrService.warning('Não foi possível salvar o lead, salvando offline');

        console.log(error);
      }
    );
  }

  getInfo() {

    this.getCursos();
    this.getEscolaridades();

    /*this.getMetodologias();*/
  }

  selectCurso() {
    this.cursoSelecionado = this.formLead.controls['curso'].value;

    // Remover duplicatas baseadas no nome do curso
    const cursosUnicos = new Set();
    const cursosFiltrados = this.cursos.filter(item => {
      const nomeCursoTrimmed = item.nomeCurso.trim().toLowerCase();
      if (!cursosUnicos.has(nomeCursoTrimmed)) {
        cursosUnicos.add(nomeCursoTrimmed);
        return true;
      }
      return false;
    });

    // Atualizar o código do curso (codCurso) com base na seleção
    const cursoSelecionado = cursosFiltrados.find(item => item.nomeCurso === this.cursoSelecionado);
    this.codCurso = cursoSelecionado ? cursoSelecionado.id : null;

    this.getMetodologias();
  }

  getMetodologias() {
    this.cursoSelecionado = this.formLead.controls['curso'].value;
    this._geraisService.getMetodologiasBanco(this.cursoSelecionado).subscribe(
      response => {
        if (response.sucesso) {
          this.metodologias = response.resultado;
        } else {
          this._toastrService.error(response.mensagem);
        }
      },
      error => {
        this._toastrService.error('Um erro ocorreu, tente novamente mais tarde.');
      }
    );
  }

  selectMetodologia() {
    this.cursoSelecionado = this.formLead.controls['curso'].value;
    this.modalidadeSelecionada = this.formLead.controls['metodologia'].value;

    // Encontrar a metodologia selecionada e recuperar indModalidade
    const metodologiaSelecionada = this.metodologias.find(item => item.nomeMetodologia === this.modalidadeSelecionada);
    this.indModalidade = metodologiaSelecionada ? metodologiaSelecionada.indModalidade : null;
    // Armazenar idMetodologia em uma variável
    this.idMetodologia = metodologiaSelecionada ? metodologiaSelecionada.id : null;

    this.getTurnoPorCurso(this.cursoSelecionado, this.modalidadeSelecionada);
  }


  getTurnoPorCurso(curso: string, modalidade: string) {
    this._geraisService.getTurnoBanco(curso, modalidade).subscribe(
      response => {
        if (response.sucesso) {
          this.turnos = response.resultado;
        } else {
          this._toastrService.error(response.mensagem);
        }
      },
      error => {
        this._toastrService.error('Um erro ocorreu, tente novamente mais tarde.');
      }
    );
  }

  selectTurno() {
    // Limpar o campo turno quando o turno é alterado
    this.formLead.controls['campus'].setValue(null);

    this.cursoSelecionado = this.formLead.controls['curso'].value;
    this.modalidadeSelecionada = this.formLead.controls['metodologia'].value;
    this.turnoSelecionado = this.formLead.controls['turno'].value;

    // Remover duplicatas baseadas no nome do turno
    const turnosUnicos = new Set();
    const turnosFiltrados = this.turnos.filter(item => {
      const nomeTurnoTrimmed = item.nomeTurno.trim().toLowerCase();
      if (!turnosUnicos.has(nomeTurnoTrimmed)) {
        turnosUnicos.add(nomeTurnoTrimmed);
        return true;
      }
      return false;
    });

    // Atualizar codTurno
    const turnoSelecionado = turnosFiltrados.find(item => item.nomeTurno === this.turnoSelecionado);
    this.codTurno = turnoSelecionado ? turnoSelecionado.id : null;

    this.getCampusPorCurso(this.cursoSelecionado, this.modalidadeSelecionada, this.turnoSelecionado);
  }


  getEscolaridades() {
    this._geraisService.getEscolaridadesBanco().subscribe(
      response => {
        if (response.sucesso) {
          this.escolaridades = response.resultado;
        } else {
          this._toastrService.error(response.mensagem);
        }
      },
      error => {
        this._toastrService.error('Um erro ocorreu, tente novamente mais tarde.');
      }
    );
  }

  getCursos() {
    this._geraisService.getCursosBanco().subscribe(
      response => {
        if (response.sucesso) {
          // Remover duplicatas baseadas no nome do curso
          const cursosUnicos = new Set();
          this.cursos = response.resultado.filter(item => {
            const nomeCursoTrimmed = item.nomeCurso.trim().toLowerCase();
            if (!cursosUnicos.has(nomeCursoTrimmed)) {
              cursosUnicos.add(nomeCursoTrimmed);
              return true;
            }
            return false;
          });
        } else {
          this._toastrService.error(response.mensagem);
        }
      },
      error => {
        this._toastrService.error('Um erro ocorreu, tente novamente mais tarde.');
      }
    );
  }

  getCampusPorCurso(cursoSelecionado: string, metodologiaSelecionada: string, turnoSelecionado: string) {

    this._geraisService.getCampusBanco(cursoSelecionado, metodologiaSelecionada, turnoSelecionado).subscribe(
      response => {
        if (response.sucesso) {
          this.campus = response.resultado;
        } else {
          this._toastrService.error(response.mensagem);
        }
      },
      error => {
        this._toastrService.error('Um erro ocorreu, tente novamente mais tarde.');
      }
    );
  }

  selectCampus() {
    const nomeCampusSelecionado = this.formLead.controls['campus'].value;

    // Remover duplicatas baseadas no nome do campus
    const campusUnicos = new Set();
    const campusFiltrados = this.campus.filter(item => {
      const nomeCampusTrimmed = item.nomeCampus.trim().toLowerCase();
      if (!campusUnicos.has(nomeCampusTrimmed)) {
        campusUnicos.add(nomeCampusTrimmed);
        return true;
      }
      return false;
    });

    // Atualizar codCampus e codigoInstituicaoInteresse
    const campusSelecionado = campusFiltrados.find(item => item.nomeCampus === nomeCampusSelecionado);
    this.codCampus = campusSelecionado ? campusSelecionado.idCampus : null;
    this.codCampusPai = campusSelecionado ? campusSelecionado.codCampusPai : null;
    this.codigoInstituicaoInteresse = campusSelecionado ? campusSelecionado.codIes : null;
  }

  generateCaptcha() {
    const a = 'ABCDEFGHJKMNPQRSTUVWXYZ';
    const n = '23456789';
    let cap_size = 5; // Captcha length
    let captchaTemp = '';

    function randomizer(x: number) {
      return Math.floor(Math.random() * x);
    }

    while (cap_size--) {
      // if 0 add a random alphabetic character
      if (randomizer(2) === 0) {
        captchaTemp += a[randomizer(a.length)];
      } else {
        // if 1 add a random numeric character
        captchaTemp += n[randomizer(n.length)];
      }
    }

    this.captcha = captchaTemp;
  }

  toUpper(form, event) {
    this.formLead.controls['captcha'].setValue(event.target.value.toUpperCase());
  }

  logRequiredFields() {
    //Essa funcao de diz qual campo do formulario não está valido
    event.preventDefault();
    Object.keys(this.formLead.controls).forEach(fieldName => {
      const control = this.formLead.controls[fieldName];
      if (control.status != 'VALID') {
        console.log(fieldName);
      }
    });
  }
}
